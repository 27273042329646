<template>
  <v-container class="pa-0 mx-0" fluid>
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              v-bind="attrs"
              v-blur
              v-on="{ ...tooltip, ...dialog }"
              :color="filterApplied ? 'orange' : 'msaBlue'"
              class="white--text"
            >
              <v-icon> mdi-filter </v-icon>
            </v-btn>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </template>
      <v-card tile>
        <v-card-title class="headline msaBlue white--text">
          <v-row>
            <v-col class="grow"> Filters </v-col>
            <v-col class="shrink">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters class="my-3">
            <v-col class="font-weight-bold" cols="12"> User </v-col>
            <v-col>
              <CustomizedAutoComplete
                :items="filteredUsers"
                :value="employeeFilters.users"
                @change="employeeFilters.users = $event"
                clearable
                deletable-chips
                hide-details
                item-text="fullname"
                label="Select user(s)"
                multiple
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="my-3">
            <v-col cols="12" class="font-weight-bold"> Supervisor </v-col>
            <v-col>
              <CustomizedAutoComplete
                :items="supervisors"
                :value="employeeFilters.supervisors"
                @change="employeeFilters.supervisors = $event"
                clearable
                deletable-chips
                hide-details
                item-text="fullname"
                label="Select supervisor(s)"
                multiple
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="my-3">
            <v-col cols="12" class="font-weight-bold"> Group </v-col>
            <v-col>
              <CustomizedAutoComplete
                :items="labels"
                :value="employeeFilters.labels"
                @change="employeeFilters.labels = $event"
                clearable
                deletable-chips
                hide-details
                label="Select group(s)"
                multiple
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="my-3">
            <v-col cols="12" class="font-weight-bold"> Email </v-col>
            <v-col>
              <CustomizedAutoComplete
                :items="emails"
                :value="employeeFilters.emails"
                @change="employeeFilters.emails = $event"
                clearable
                deletable-chips
                hide-details
                label="Select email(s)"
                multiple
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="my-3">
            <v-col cols="12" class="font-weight-bold"> Hire Date </v-col>
            <v-col class="mr-2" :cols="isMobile ? 12 : false">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                dense
                min-width="auto"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-model="employeeFilters.startDate"
                    v-on="on"
                    clearable
                    dense
                    hide-details
                    outlined
                    placeholder="From"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="employeeFilters.startDate"
                  @input="startDateMenu = false"
                  dense
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col :cols="isMobile ? 12 : false">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                dense
                min-width="auto"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-model="employeeFilters.endDate"
                    v-on="on"
                    clearable
                    dense
                    hide-details
                    outlined
                    placeholder="To"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="employeeFilters.endDate"
                  @input="endDateMenu = false"
                  dense
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-3">
            <v-col cols="12" class="font-weight-bold"> User Added </v-col>
            <v-col class="mr-2" :cols="isMobile ? 12 : false">
              <v-menu
                v-model="createdFromMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                dense
                min-width="auto"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-model="employeeFilters.createdFrom"
                    v-on="on"
                    clearable
                    dense
                    hide-details
                    outlined
                    placeholder="From"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="employeeFilters.createdFrom"
                  @input="createdFromMenu = false"
                  dense
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col :cols="isMobile ? 12 : false">
              <v-menu
                v-model="createdToMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                dense
                min-width="auto"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-model="employeeFilters.createdTo"
                    v-on="on"
                    clearable
                    dense
                    hide-details
                    outlined
                    placeholder="To"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="employeeFilters.createdTo"
                  @input="createdToMenu = false"
                  dense
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-3">
            <v-col cols="12" class="font-weight-bold"> Status </v-col>
            <v-col class="pb-2">
              <v-btn-toggle
                v-model="employeeFilters.status"
                color="msaBlue"
                dense
                mandatory
                style="width: 100%"
              >
                <v-btn :value="2" class="text-capitalize" style="width: 33%">
                  All
                </v-btn>
                <v-btn :value="1" class="text-capitalize" style="width: 33%">
                  Active
                </v-btn>
                <v-btn :value="0" class="text-capitalize" style="width: 33%">
                  Inactive
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-scale-transition>
              <v-col v-if="employeeFilters.status != 1" cols="12">
                <v-alert dense type="warning">
                  Mass Edit Users feature will be disabled
                </v-alert>
              </v-col>
            </v-scale-transition>
          </v-row>
          <v-slide-y-transition>
            <v-row class="my-3" no-gutters>
              <v-col cols="12" class="font-weight-bold"> Web Admin </v-col>
              <v-col class="pb-2">
                <v-btn-toggle
                  v-model="employeeFilters.isWebAdministrator"
                  color="msaBlue"
                  dense
                  mandatory
                  style="width: 100%"
                >
                  <v-btn :value="2" class="text-capitalize" style="width: 33%">
                    All
                  </v-btn>
                  <v-btn :value="1" class="text-capitalize" style="width: 33%">
                    Yes
                  </v-btn>
                  <v-btn :value="0" class="text-capitalize" style="width: 33%">
                    No
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-slide-y-transition>
          <v-slide-y-transition>
            <v-row class="my-3" no-gutters>
              <v-col cols="12" class="font-weight-bold"> Supervisor </v-col>
              <v-col class="pb-2">
                <v-btn-toggle
                  v-model="employeeFilters.isSupervisor"
                  color="msaBlue"
                  dense
                  mandatory
                  style="width: 100%"
                >
                  <v-btn :value="2" class="text-capitalize" style="width: 33%">
                    All
                  </v-btn>
                  <v-btn :value="1" class="text-capitalize" style="width: 33%">
                    Yes
                  </v-btn>
                  <v-btn :value="0" class="text-capitalize" style="width: 33%">
                    No
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-slide-y-transition>
          <v-slide-y-transition>
            <v-row
              v-if="employeeFilters.isSupervisor != 0"
              class="my-3"
              no-gutters
            >
              <v-col cols="12" class="font-weight-bold"> Manager </v-col>
              <v-col class="pb-2">
                <v-btn-toggle
                  v-model="employeeFilters.isManager"
                  color="msaBlue"
                  dense
                  mandatory
                  style="width: 100%"
                >
                  <v-btn :value="2" class="text-capitalize" style="width: 33%">
                    All
                  </v-btn>
                  <v-btn :value="1" class="text-capitalize" style="width: 33%">
                    Yes
                  </v-btn>
                  <v-btn :value="0" class="text-capitalize" style="width: 33%">
                    No
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-slide-y-transition>
          <v-slide-y-transition>
            <v-row class="my-3" no-gutters>
              <v-col cols="12" class="font-weight-bold"> Safety </v-col>
              <v-col class="pb-2">
                <v-btn-toggle
                  v-model="employeeFilters.isSafetyRep"
                  color="msaBlue"
                  dense
                  mandatory
                  style="width: 100%"
                >
                  <v-btn :value="2" class="text-capitalize" style="width: 33%">
                    All
                  </v-btn>
                  <v-btn :value="1" class="text-capitalize" style="width: 33%">
                    Yes
                  </v-btn>
                  <v-btn :value="0" class="text-capitalize" style="width: 33%">
                    No
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-slide-y-transition>
          <v-row
            v-if="defaultCustomInputFields.length && dialog"
            no-gutters
            class="my-3"
          >
            <v-col class="font-weight-bold" cols="12">Custom Fields</v-col>
            <v-col
              v-for="field in defaultCustomInputFields"
              :key="field.id"
              cols="12"
            >
              <v-text-field
                v-model.trim="employeeFilters[`${field.name}.${field.id}`]"
                :label="field.name"
                outlined
                dense
                maxLength="500"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!filterApplied"
            @click="clear()"
            class="mr-5"
            color="red"
            text
          >
            CLEAR
          </v-btn>
          <v-btn @click="applyFilters()" color="msaBlue white--text">
            APPLY
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'CompanyUserFilter',
  props: {
    defaultCustomInputFields: {
      type: Array,
    },
    isFilterNeedingToReload: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      employeeFilters: {},
      defaultFilters: {},
      startDateMenu: false,
      endDateMenu: false,
      createdFromMenu: false,
      createdToMenu: false,
      labels: [],
      users: [],
    };
  },
  computed: {
    filterApplied() {
      if (!this.employeeFilters) {
        return false;
      }

      return (
        JSON.stringify(this.employeeFilters) !=
        JSON.stringify(this.defaultFilters)
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    emails() {
      return [
        ...new Set(
          this.filteredUsers.filter((u) => u.email != '').map((u) => u.email),
        ),
      ];
    },
    supervisors() {
      return this.users.filter((u) => u.status == 'Active' && u.isSupervisor);
    },
    filteredUsers() {
      if (this.employeeFilters.status == 1) {
        return this.users.filter((u) => u.status == 'Active');
      }
      if (this.employeeFilters.status == 0) {
        return this.users.filter((u) => u.status == 'Inactive');
      }
      return this.users;
    },
  },
  watch: {
    'employeeFilters.isSupervisor'(newVal, oldVal) {
      // when supervisor is NO
      // then manager is NO
      if (newVal == 0) {
        this.employeeFilters.isManager = 0;
      } else {
        // When change supervisor from NO to YES
        // Change manager back to ALL
        if (oldVal == 0) {
          this.employeeFilters.isManager = 2;
        }
      }
    },
    dialog(isOpen) {
      if (isOpen && this.isFilterNeedingToReload) {
        this.getDataForFilter();
      }
    },
  },
  methods: {
    getDataForFilter() {
      const params = {
        loaderText: 'Loading...',
      };
      if (this.$store.getters.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }
      const url = 'get-data-for-user-filter?format=json';
      this.$axios.post(url, params).then((response) => {
        this.labels = response.data.labels;
        this.users = response.data.users;
        this.$emit('filterReloaded');
      });
    },
    applyFilters() {
      this.employeeFilters.startDate = this.employeeFilters.startDate ?? '';
      this.employeeFilters.endDate = this.employeeFilters.endDate ?? '';
      this.employeeFilters.createdFrom = this.employeeFilters.createdFrom ?? '';
      this.employeeFilters.createdTo = this.employeeFilters.createdTo ?? '';
      this.dialog = false;

      this.$emit('applyFilters', this.employeeFilters);
    },
    clear() {
      this.employeeFilters = JSON.parse(JSON.stringify(this.defaultFilters));
      this.applyFilters();
    },
    setFilters(filters) {
      this.employeeFilters = JSON.parse(JSON.stringify(filters));
    },
    setDefaultFilters(defaultFilters) {
      this.defaultFilters = JSON.parse(JSON.stringify(defaultFilters));
    },
  },
};
</script>
