<template>
  <v-btn class="msaBlue white--text mr-2" @click="downloadSpreadsheet()">
    Download Spreadsheet
  </v-btn>
</template>

<script>
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
export default {
  name: 'UserMassImportDownload',
  props: {
    labels: {
      type: Array,
      required: true,
    },
    supervisors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    supervisorNames() {
      return this.supervisors.map((i) => [i.fullname]);
    },
  },
  methods: {
    downloadSpreadsheet() {
      const workbook = new Workbook();

      this.createSpreadsheet(workbook);

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fs.saveAs(blob, 'Mass Upload Users.xlsx');
      });
    },
    createSpreadsheet(workbook) {
      const worksheet = workbook.addWorksheet('Users', {
        views: [{ state: 'frozen', ySplit: 2 }],
      });

      worksheet.protect(this.$constants.EXCEL_PASSWORD);

      // Insert supervisor names into another worksheet for formulae use
      const supervisorNamesHiddenWorksheet =
        workbook.addWorksheet('SupervisorNames');
      supervisorNamesHiddenWorksheet.insertRows(1, this.supervisorNames);
      supervisorNamesHiddenWorksheet.state = 'veryHidden';

      this.formatHeadersAndFillData(worksheet);
    },
    formatHeadersAndFillData(worksheet) {
      const headerRow = worksheet.getRow(1);
      const subheaderRow = worksheet.getRow(2);

      // Setup the count column
      worksheet.getColumn(1).width = 4;
      worksheet.getColumn(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F2F2F2' },
      };
      headerRow.getCell(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
      };
      subheaderRow.getCell(1).value = '#';

      // Setup General Information Section
      const generalInfoSubheaders = [
        'Email',
        'First Name',
        'Last Name',
        'Phone Number',
        'Username',
        'Hire Date (yyyy-mm-dd)',
        'Assigned Supervisor',
        'Title',
      ];
      const generalInfoStartColumn = 2;
      const generalInfoEndColumn =
        generalInfoStartColumn + generalInfoSubheaders.length - 1;
      worksheet.mergeCells('B1:I1');
      headerRow.getCell(generalInfoStartColumn).value = 'General Information';
      headerRow.getCell(generalInfoStartColumn).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'BDD7EE' },
      };
      generalInfoSubheaders.forEach((item, index) => {
        subheaderRow.getCell(generalInfoStartColumn + index).value = item;
        subheaderRow.getCell(generalInfoStartColumn + index).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };

        worksheet.getColumn(generalInfoStartColumn + index).width = 25;
      });

      // Setup Permissions Section
      const permissionSubheaders = [
        'Web Admin',
        'Supervisor',
        'Manager',
        'Safety',
      ];
      const permissionStartColumn = generalInfoEndColumn + 1;
      const permissionEndColumn =
        permissionStartColumn + permissionSubheaders.length - 1;
      worksheet.mergeCells('J1:M1');
      headerRow.getCell(permissionStartColumn).value = 'Permissions';
      headerRow.getCell(permissionStartColumn).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'C6E0B4' },
      };
      permissionSubheaders.forEach((item, index) => {
        subheaderRow.getCell(permissionStartColumn + index).value = item;
        subheaderRow.getCell(permissionStartColumn + index).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };

        worksheet.getColumn(permissionStartColumn + index).width = 25;
      });

      // Setup Labels Section
      const labelStartColumn = permissionEndColumn + 1;
      const labelEndColumn =
        labelStartColumn +
        (this.labels.length > 0 ? this.labels.length - 1 : 0);
      if (this.labels.length) {
        worksheet.mergeCells(
          1,
          permissionEndColumn + 1,
          1,
          permissionEndColumn + this.labels.length,
        );
        headerRow.getCell(labelStartColumn).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE699' },
        };
        headerRow.getCell(labelStartColumn).value = 'Labels';

        this.labels.forEach((label, index) => {
          subheaderRow.getCell(labelStartColumn + index).value = label.name;
          subheaderRow.getCell(labelStartColumn + index).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF2CC' },
          };

          worksheet.getColumn(labelStartColumn + index).width = 25;
        });
      }

      // Filling Data
      const entryStartRow = 3;
      const entryEndRow = 102;
      for (let rowNum = entryStartRow; rowNum <= entryEndRow; rowNum++) {
        // get row
        const row = worksheet.getRow(rowNum);

        // fill count column
        const countCell = row.getCell(1);
        countCell.value = rowNum - 2;

        // fill supervisor dropdown
        const supervisorCell = row.getCell(generalInfoEndColumn - 1);
        supervisorCell.dataValidation = {
          allowBlank: true,
          showErrorMessage: true,
          errorStyle: 'error',
          errorTitle: 'Invalid Selection',
          error: 'Please select a value from the dropdown options.',
          type: 'list',
          formulae: ['=SupervisorNames!$A$1:$A$9999'],
        };

        // fill username formula
        const usernameCell = row.getCell(6);
        usernameCell.value = {
          formula: `IF(B${rowNum}="", CONCATENATE(LEFT(C${rowNum}, 1), D${rowNum}), B${rowNum})`,
          result: '',
        };

        // fill hire date formula
        const hireDateCell = row.getCell(7);
        hireDateCell.dataValidation = {
          type: 'date',
          operator: 'between',
          allowBlank: true,
          showErrorMessage: true,
          formulae: [
            new Date(this.$constants.DATE_SELECTOR_RANGE.MIN_EXCLUSIVE),
            new Date(this.$constants.DATE_SELECTOR_RANGE.MAX_EXCLUSIVE),
          ],
        };

        for (let col = generalInfoStartColumn; col <= labelEndColumn; col++) {
          const cell = row.getCell(col);
          cell.protection = { locked: false };

          if (col > generalInfoEndColumn) {
            cell.dataValidation = {
              type: 'list',
              allowBlank: false,
              formulae: ['"On,Off"'],
              showErrorMessage: true,
              errorStyle: 'error',
              errorTitle: 'Invalid Selection',
              error: 'Please select a value from the dropdown options.',
            };
            cell.value = 'Off';
          }
        }
      }
    },
  },
};
</script>

<style></style>
