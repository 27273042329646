<template>
  <v-file-input
    v-model="file"
    :error-messages="errorMessages"
    @change="processData($event)"
    @clear="clearFile()"
    accept=".xlsx"
    dense
    label="Click here to upload your filled out template"
    outlined
  >
  </v-file-input>
</template>

<script>
import { DateTime } from 'luxon';
import { Workbook } from 'exceljs';
export default {
  name: 'UserMassImportUpload',
  props: {
    labels: {
      type: Array,
      required: true,
    },
    supervisors: {
      type: Array,
    },
  },
  data() {
    return {
      file: null,
      errorMessages: '',
      entries: [],
    };
  },
  computed: {
    subheaders() {
      return [
        '#',
        'Email',
        'First Name',
        'Last Name',
        'Phone Number',
        'Username',
        'Hire Date (yyyy-mm-dd)',
        'Assigned Supervisor',
        'Title',
        'Web Admin',
        'Supervisor',
        'Manager',
        'Safety',
      ].concat(this.labels.map((l) => l.name));
    },
  },
  methods: {
    async processData() {
      this.errorMessages = '';
      if (!this.file) {
        return;
      }

      try {
        await this.$helpers.processFile(
          this.file,
          this.$constants.ACCEPTED_MIME_TYPES.EXCEL,
          true,
        );
      } catch (error) {
        if (error.message) {
          this.$root.showMessage(
            'Error',
            error.message,
            () => null,
            null,
            'ok',
          );
        }
        this.clearFile();
        return;
      }

      const reader = new FileReader();
      const wb = new Workbook();

      reader.readAsArrayBuffer(this.file);
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {
          const worksheet = workbook.getWorksheet(1);

          if (!this.validateSpreadsheetColumns(worksheet)) {
            return;
          }

          this.entries = [];

          const entryStart = 3; // First row to user data in (After headers)
          const entryEnd = 102; // Last row
          for (let rowNum = entryStart; rowNum <= entryEnd; rowNum++) {
            const entry = { labels: [], assignedSupervisors: [] };
            const row = worksheet.getRow(rowNum);

            row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
              const header = this.subheaders[colNumber - 1];

              const cellValue = cell.value ?? '';
              // get general info
              if (colNumber <= 9) {
                if (typeof cellValue == 'object') {
                  // format hire date
                  if (header == 'Hire Date (yyyy-mm-dd)') {
                    if (DateTime.fromJSDate(new Date(cellValue)).isValid) {
                      entry['Hire Date (yyyy-mm-dd)'] = DateTime.fromISO(
                        new Date(cellValue).toISOString(),
                      )
                        .toUTC()
                        .toFormat('yyyy-LL-dd');
                    } else {
                      entry[header] = '';
                    }
                  } else {
                    entry[header] = cellValue.result
                      ? cellValue.result.toString().trim()
                      : cellValue.text
                      ? cellValue.text.toString().trim()
                      : '';
                  }
                } else {
                  entry[header] = cellValue.toString().trim();
                }

                // get assigned supervisor
                if (header == 'Assigned Supervisor') {
                  const supervisor = this.supervisors.find(
                    (s) => s.fullname == cellValue,
                  );
                  if (supervisor) {
                    entry.assignedSupervisors.push(supervisor.id);
                  }
                }
              }

              // get permissions
              if (colNumber > 9 && colNumber <= 13) {
                if (cellValue == 'On') {
                  entry[header] = true;
                }
                if (cellValue == 'Off') {
                  entry[header] = false;
                }
              }

              // get labels
              if (colNumber > 13 && cellValue == 'On') {
                const label = this.labels.find((l) => l.name == header);
                if (label) {
                  entry.labels.push(label.id);
                }
              }
            });

            if (
              entry['Username'] ||
              entry['First Name'] ||
              entry['Last Name'] ||
              entry['Phone Number'] ||
              entry['Title'] ||
              entry['Hire Date (yyyy-mm-dd)'] ||
              entry['Email'] ||
              entry.assignedSupervisors.length ||
              entry.labels.length
            ) {
              this.entries.push({
                username: entry['Username'],
                firstName: entry['First Name'],
                lastName: entry['Last Name'],
                phoneNumber: entry['Phone Number'],
                hireDate: entry['Hire Date (yyyy-mm-dd)'],
                assignedSupervisors: entry.assignedSupervisors,
                email: entry['Email'],
                isWebAdministrator: entry['Web Admin'],
                isSupervisor: entry['Supervisor'],
                isManager: entry['Manager'],
                isSafetyRep: entry['Safety'],
                labels: entry.labels,
                sendEmail: true,
                hireDateMenu: false,
                title: entry['Title'],
                id: this.$helpers.createGuid(),
              });
            }
          }

          if (!this.entries.length) {
            this.errorMessages = 'No entry found.';
            this.file = null;
            return;
          }
          this.$emit('uploaded', this.entries);
          this.clearFile();
        });
      };
    },
    validateSpreadsheetColumns(worksheet) {
      // get all headers from spreadsheet
      const wsSubheaders = worksheet
        .getRow(2)
        .values.filter((i) => i != undefined);

      if (wsSubheaders.toString() != this.subheaders.toString()) {
        this.clearFile();
        this.errorMessages =
          'Invalid spreadsheet. Please download and try again.';
        return false;
      }
      return true;
    },
    clearFile() {
      this.file = null;
      this.errorMessages = '';
    },
  },
};
</script>
