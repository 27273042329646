<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            v-blur
            :disabled="!isCompanyActive"
            @click="getDataForMassImport()"
            class="white--text"
            color="msaBlue"
          >
            <v-icon>mdi-plus-box-multiple-outline</v-icon>
          </v-btn>
        </template>
        Mass Import
      </v-tooltip>
    </template>
    <v-card id="mass-import-card" tile>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col class="grow"> Mass Import Users </v-col>
          <v-col class="shrink">
            <v-btn @click="close()" icon>
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-container fluid>
        <v-row align="center" class="mt-2">
          <v-col class="py-0">
            <PageInstruction
              :contents="massImportInstructions"
              @clicked="$refs.massImportDownload.downloadSpreadsheet()"
              clickEventId="mass-import-download"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="shrink">
            <UserMassImportDownload
              :labels="companySpecificLabels"
              :supervisors="supervisors"
              ref="massImportDownload"
            />
          </v-col>
          <v-col v-if="newEmployees.length" class="shrink">
            <v-btn @click="submit()" class="green white--text">confirm</v-btn>
          </v-col>
          <v-col v-if="newEmployees.length" class="grow">
            <v-checkbox
              v-model="sendEmail"
              :indeterminate="sendEmailIndeterminate"
              @change="onGlobalSendEmailChanged()"
              class="mt-1"
              color="msaBlue"
              hide-details
              label="Send Email For All"
            />
          </v-col>
          <v-col v-if="newEmployees.length" class="shrink">
            <v-btn
              @click="
                eraseRecords();
                getDataForMassImport();
              "
              class="red white--text"
            >
              Remove all entries
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-show="!newEmployees.length">
          <v-col class="pb-0">
            <UserMassImportUpload
              :labels="companySpecificLabels"
              :supervisors="supervisors"
              @uploaded="validateEntries($event)"
            />
          </v-col>
        </v-row>
        <v-row v-if="newEmployees.length">
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row v-if="newEmployees.length">
          <v-col cols="12" align="center" class="py-0 text-h6">
            This is the temporary password for all users being uploaded during
            this session.
          </v-col>
          <v-col align="center" class="py-0 text-h5">
            {{ password }}
          </v-col>
          <v-col align="center" class="pb-0" cols="12">
            <v-alert dense outlined type="warning" class="text-body-1">
              You can resolve any issues by editing the data in the table below
              and then pressing the green <b>Confirm</b> button above to
              validate your work.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(error, index) in generalErrors"
            :key="index"
            align="center"
            class="pb-0"
            cols="12"
          >
            <v-alert class="text-body-1" dense outlined type="error">
              {{ error }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="newEmployees.length > 0">
          <v-col v-for="(entry, index) in newEmployees" :key="index" cols="12">
            <v-card class="pa-4">
              <v-card-text>
                <v-form ref="users">
                  <v-row dense>
                    <v-col md="6" sm="12">
                      <v-text-field
                        v-model="entry.firstName"
                        :error-messages="entry.errors.firstName"
                        :rules="rules.firstName"
                        @keydown="clearServerErrors(entry, 'firstName')"
                        counter
                        dense
                        maxlength="75"
                        outlined
                        label="First Name"
                      />
                    </v-col>
                    <v-col md="6" sm="12">
                      <v-text-field
                        v-model="entry.lastName"
                        :error-messages="entry.errors.lastName"
                        :rules="rules.lastName"
                        @keydown="clearServerErrors(entry, 'lastName')"
                        counter
                        dense
                        label="Last name"
                        maxlength="75"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col md="6" sm="12">
                      <v-text-field
                        v-model="entry.email"
                        :error-messages="entry.errors.email"
                        :rules="[rules.email(entry)]"
                        @keydown="clearServerErrors(entry, 'email')"
                        counter
                        dense
                        label="Email"
                        maxlength="75"
                        outlined
                      />
                    </v-col>
                    <v-col md="6" sm="12">
                      <v-text-field
                        v-model="entry.phoneNumber"
                        :error-messages="entry.errors.phoneNumber"
                        :rules="rules.phoneNumber"
                        @keydown="clearServerErrors(entry, 'phoneNumber')"
                        counter
                        dense
                        label="Phone Number"
                        maxlength="14"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col md="6" sm="12">
                      <v-text-field
                        v-model="entry.username"
                        :error-messages="entry.errors.username"
                        :rules="rules.username"
                        @keydown="clearServerErrors(entry, 'username')"
                        counter
                        dense
                        label="Username"
                        maxlength="64"
                        outlined
                      />
                    </v-col>
                    <v-col md="6" sm="12">
                      <v-menu
                        v-model="entry.hireDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="auto"
                        offset-y
                        transition="scale-transition"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="entry.hireDate"
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="entry.errors.hireDate"
                            :rules="rules.date"
                            clearable
                            dense
                            label="Hire date"
                            outlined
                            readonly
                          />
                        </template>
                        <v-date-picker
                          v-model="entry.hireDate"
                          @input="
                            entry.hireDateMenu = false;
                            clearServerErrors(entry, 'hireDate');
                          "
                          no-title
                          scrollable
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col md="6" sm="12">
                      <v-text-field
                        v-model="entry.title"
                        @keydown="clearServerErrors(entry, 'title')"
                        counter
                        dense
                        label="Title"
                        maxlength="200"
                        outlined
                      />
                    </v-col>
                    <!-- v-spacer didn't leave correct amount of space oddly enough -->
                    <v-col md="6" sm="12"></v-col>
                  </v-row>

                  <v-row dense>
                    <v-col md="3" xs="6">
                      <v-switch
                        v-model="entry.isWebAdministrator"
                        color="msaBlue"
                        label="Web Admin"
                      />
                    </v-col>
                    <v-col md="3" xs="6">
                      <v-switch
                        v-model="entry.isSupervisor"
                        color="msaBlue"
                        label="Supervisor"
                      />
                    </v-col>
                    <v-col md="3" xs="6">
                      <v-switch
                        v-model="entry.isManager"
                        :disabled="!entry.isSupervisor"
                        color="msaBlue"
                        label="Manager"
                      />
                    </v-col>
                    <v-col md="3" xs="6">
                      <v-switch
                        v-model="entry.isSafetyRep"
                        color="msaBlue"
                        label="Safety"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col md="6" sm="12">
                      <CustomizedAutoComplete
                        :items="companySpecificLabels"
                        :value="entry.labels"
                        @change="entry.labels = $event"
                        clearable
                        deletable-chips
                        label="Groups"
                        multiple
                      />
                    </v-col>
                    <v-col md="6" sm="12">
                      <CustomizedAutoComplete
                        :items="supervisors"
                        :value="entry.assignedSupervisors"
                        @change="entry.assignedSupervisors = $event"
                        clearable
                        deletable-chips
                        hide-details
                        item-text="fullname"
                        label="Supervisors"
                        multiple
                      />
                    </v-col>
                  </v-row>

                  <v-row align="center" dense justify="space-between">
                    <v-checkbox
                      v-model="entry.sendEmail"
                      @change="onIndividualSendEmailChanged()"
                      label="Send Email"
                    />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="removeEntry(index)"
                          color="red"
                          icon
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      Remove Entry
                    </v-tooltip>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { PasswordGenerator } from '@/plugins/PasswordGenerator.js';
import PageInstruction from '@/components/PageInstruction.vue';
import UserMassImportDownload from '@/components/User/UserMassImportDownload.vue';
import UserMassImportUpload from '@/components/User/UserMassImportUpload.vue';
export default {
  name: 'UserMassImport',
  components: {
    PageInstruction,
    UserMassImportDownload,
    UserMassImportUpload,
  },
  computed: {
    isCompanyActive() {
      return this.$store.getters.selectedCompany.status;
    },
  },
  data() {
    return {
      dialog: false,
      companySpecificLabels: [],
      supervisors: [],
      newEmployees: [],
      password: '',
      generalErrors: [],
      rules: {
        date: [(v) => this.$helpers.validateDate(v)],
        username: [
          (v) => this.$helpers.required(v, 'Username'),
          (v) => this.$helpers.validateUsername(v),
        ],
        phoneNumber: [(v) => this.$helpers.validatePhone(v)],
        firstName: [(v) => this.$helpers.required(v, 'First Name')],
        lastName: [(v) => this.$helpers.required(v, 'Last Name')],
        email: (entry) => (v) =>
          this.$helpers.validateEmail(v, entry.isWebAdministrator),
      },
      sendEmail: true,
      sendEmailIndeterminate: false,
      massImportInstructions: [
        'Step 1: Download the <span style="cursor: pointer; color: #3564ac" id="mass-import-download">User Upload Spreadsheet</span>.',
        'Step 2: Open the User Upload Spreadsheet with Microsoft Excel.',
        'Step 3: Enter each user on a separate line. *A maximum of 100 users can be uploaded at a time.',
        'Step 4: SAVE and CLOSE the file when you are done editing.',
        'Step 5: Select the "Click here to upload your filled out template" and select your file to upload it to the server.',
        'Step 6: Verify user data in the table below. Resolve any issues that might have occurred.',
        'Step 7: Press the Confirm button to complete the upload process.',
      ],
    };
  },
  methods: {
    clearServerErrors(entry, key) {
      if (entry.errors[key].length > 0) {
        entry.errors[key] = [];
      }
    },
    setNullToEmptyString(entries) {
      /*
        Hire date is the only text-field that is clearable. Clearing the text field sets a value to null.
      */
      for (const entry of entries) {
        if (entry.hireDate == null) {
          entry.hireDate = '';
        }
      }
    },
    validateEntries(entries) {
      this.setNullToEmptyString(entries);

      const params = {
        loaderText: 'Loading... ',
        entries: entries,
      };

      if (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      ) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }

      this.$axios
        .post('validate-entries-for-user-mass-import?format=json', params)
        .then((response) => {
          this.newEmployees = response.data.entries;
          this.generalErrors = response.data.generalErrors;
          this.generatePassword();
          this.$nextTick(() =>
            this.$refs.users.forEach((user) => user.validate()),
          );
        })
        .catch((e) => e);
    },
    submit() {
      const url = 'mass-import-users?format=json';

      const params = {
        entries: this.newEmployees,
        loaderText: 'Saving...',
      };

      if (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      ) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }

      this.$axios
        .post(url, params)
        .then(() => {
          this.dialog = false;
          this.$emit('reload');
        })
        .catch((error) => {
          this.newEmployees = error.response.data.data.entries;
          this.generalErrors = error.response.data.data.generalErrors;
        });
    },
    generatePassword() {
      this.password = PasswordGenerator.generate(
        6,
        this.$constants.PASSWORD_VALIDATION_REGEX,
      );
      this.newEmployees.forEach((e) => (e.password = this.password));
    },
    getDataForMassImport() {
      const params = {
        loaderText: 'Loading... ',
      };

      if (this.$store.getters.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }

      this.$axios
        .post('get-data-for-user-mass-import?format=json', params)
        .then((response) => {
          this.companySpecificLabels = response.data.companySpecificLabels;
          this.supervisors = response.data.supervisors;
        })
        .catch((e) => e);
    },
    close() {
      this.eraseRecords();
      this.dialog = false;
    },
    eraseRecords() {
      this.newEmployees = [];
      this.generalErrors = [];
      this.password = '';
      this.sendEmail = true;
      this.sendEmailIndeterminate = false;
    },
    removeEntry(index) {
      this.newEmployees.splice(index, 1);
      this.validateEntries(this.newEmployees);
    },
    onIndividualSendEmailChanged() {
      const hasNoSend = this.newEmployees.some((i) => !i.sendEmail);
      const hasSend = this.newEmployees.some((i) => i.sendEmail);

      const allSend = !hasNoSend && hasSend;
      const allNoSend = !hasSend && hasNoSend;

      if (allSend) {
        this.sendEmail = true;
        this.sendEmailIndeterminate = false;
      }

      if (allNoSend) {
        this.sendEmail = false;
        this.sendEmailIndeterminate = false;
      }

      if (!allSend && !allNoSend) {
        this.sendEmail = null;
        this.sendEmailIndeterminate = true;
      }
    },
    onGlobalSendEmailChanged() {
      this.sendEmailIndeterminate = false;
      this.newEmployees.forEach((i) => (i.sendEmail = this.sendEmail));
    },
  },
};
</script>

<style>
#mass-import-card {
  background: url('../../assets/background.png') center center fixed !important;
  background-size: cover !important;
}
</style>
<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
