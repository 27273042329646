<template>
  <v-container fluid>
    <v-row align="center" class="mt-2">
      <v-col class="py-0">
        <PageInstruction
          :contents="pageInstruction.contents"
          :title="pageInstruction.title"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <SearchBar
          ref="searchBar"
          @search="onSearch($event)"
          searchLabel="Search by name..."
        />
      </v-col>
      <v-spacer />
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="!isCompanyActive"
              @click="goToCreateUser()"
              class="msaBlue white--text"
              data-test-id="add-user-button"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          Add User
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <UserMassImport
          :key="reload"
          @reload="
            reload = !reload;
            onPageLoad();
          "
        />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              v-on="{ ...tooltip }"
              v-blur
              :disabled="filters.status != 1"
              @click="goToMassEdit"
              class="white--text"
              color="msaBlue"
            >
              <v-icon>mdi-account-group</v-icon>
            </v-btn>
          </template>
          Mass Edit Users
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <CompanyUserFilter
          ref="filters"
          :defaultCustomInputFields="defaultCustomInputFields"
          :isFilterNeedingToReload="isFilterNeedingToReload"
          @applyFilters="onApplyFilters($event)"
          @filterReloaded="isFilterNeedingToReload = false"
        />
      </v-col>
      <v-col class="shrink">
        <v-menu offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-bind="attrs"
                  v-blur
                  v-on="{ ...menu, ...tooltip }"
                  class="msaBlue white--text"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              Export
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="onExportUsers()">
              Export user details
            </v-list-item>
            <v-list-item @click="onExportUsers(true)">
              Export user details with activity
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            <v-row align="center">
              <v-col align="left">
                Users
                <v-badge
                  :content="resultCount"
                  :value="resultCount"
                  bordered
                  class="ml-4"
                  color="msaBlue"
                >
                </v-badge>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :headers="headers"
            :items="users"
            :no-data-text="
              filtersApplied ? 'No results found' : $vuetify.noDataText
            "
            :server-items-length="totalRecordCount"
            :options.sync="options"
          >
            <template v-slot:[`item.fullName`]="{ item }">
              <span v-if="is1LifeAccount(item)" class="grey--text">
                {{ item.fullName }}
              </span>
              <a
                v-else
                :class="item.status == 1 ? 'msaBlue--text' : 'red--text'"
                @click="goToDetail(item)"
              >
                {{ item.fullName }}
              </a>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              {{ item.email }}
            </template>
            <template v-slot:[`item.groups`]="{ item }">
              <template
                v-for="(group, index) in item.groups
                  ? JSON.parse(item.groups)
                  : []"
              >
                <v-chip v-if="index <= 2" :key="index" class="mr-2 my-1">
                  {{ group.name }}
                </v-chip>
                <span v-else :key="index">{{ group.name }}</span>
              </template>
            </template>
            <template v-slot:[`item.isWebAdministrator`]="{ item }">
              <v-checkbox
                v-model="item.isWebAdministrator"
                :disabled="
                  !item.email ||
                  !item.status ||
                  isOwnAccount(item) ||
                  is1LifeAccount(item)
                "
                :style="{ width: '50px !important' }"
                @change="onUpdateUserPermission(item, 'isWebAdministrator')"
                class="mx-auto mr-auto"
              />
            </template>
            <template v-slot:[`item.isSupervisor`]="{ item }">
              <v-checkbox
                v-model="item.isSupervisor"
                :disabled="!item.status || is1LifeAccount(item)"
                :style="{ width: '50px !important' }"
                @change="onUpdateUserPermission(item, 'isSupervisor')"
                class="mx-auto mr-auto"
              />
            </template>
            <template v-slot:[`item.isManager`]="{ item }">
              <v-checkbox
                v-model="item.isManager"
                :disabled="!item.status || is1LifeAccount(item)"
                :style="{ width: '50px !important' }"
                @change="onUpdateUserPermission(item, 'isManager')"
                class="mx-auto mr-auto"
              >
              </v-checkbox>
            </template>
            <template v-slot:[`item.isSafetyRep`]="{ item }">
              <v-checkbox
                v-model="item.isSafetyRep"
                :disabled="!item.status || is1LifeAccount(item)"
                :style="{ width: '50px !important' }"
                @change="onUpdateUserPermission(item, 'isSafetyRep')"
                class="mx-auto mr-auto"
              >
              </v-checkbox>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="is1LifeAccount(item)"
                    @click="goToDetail(item)"
                    color="msaBlue"
                    small
                    text
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                Edit
              </v-tooltip>
              <v-tooltip bottom v-if="item.status == 0">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="is1LifeAccount(item)"
                    @click="onChangeUserStatus(item, 1)"
                    color="green"
                    small
                    text
                  >
                    <v-icon>mdi-account-check</v-icon>
                  </v-btn>
                </template>
                Activate
              </v-tooltip>
              <v-tooltip bottom v-if="item.status == 1">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="is1LifeAccount(item) || isOwnAccount(item)"
                    @click="onChangeUserStatus(item, 0)"
                    color="red"
                    small
                    text
                  >
                    <v-icon>mdi-account-cancel-outline</v-icon>
                  </v-btn>
                </template>
                Deactivate
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyUserFilter from '@/components/Filters/CompanyUserFilter.vue';
import UserMassImport from '@/components/User/UserMassImport.vue';
import PageInstruction from '@/components/PageInstruction.vue';
import SearchBar from '@/components/SearchBar.vue';

export default {
  name: 'CompanyUsersPage',
  components: {
    CompanyUserFilter,
    PageInstruction,
    UserMassImport,
    SearchBar,
  },
  data() {
    return {
      // This is the count that includes the one life account
      totalRecordCount: 0,
      // This is the count that excludes the one life account if it exists
      resultCount: 0,
      companyId: undefined,
      page: this.$constants.PAGINATION.DEFAULT_PAGE,
      perPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      reload: false,
      reloadMassEdit: false,
      isFilterNeedingToReload: true,
      search: '',
      selectedUser: undefined,
      showDetailDialog: false,
      users: [],
      licenses: {
        licenses: 0,
        activeUsers: 0,
      },
      pageInstruction: {
        title: '',
        contents: [],
      },
      defaultCustomInputFields: [],
      headers: [
        {
          text: 'Name',
          value: 'fullName',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Email',
          value: 'email',
          align: 'start',
          class: 'lightGrey',
          sortable: false,
        },
        {
          text: 'Groups',
          value: 'groups',
          align: 'start',
          class: 'lightGrey',
          sortable: false,
          width: '300px',
        },
        {
          text: 'Web Admin',
          value: 'isWebAdministrator',
          align: 'center',
          class: 'lightGrey',
          width: '120px',
        },
        {
          text: 'Supervisor',
          value: 'isSupervisor',
          align: 'center',
          class: 'lightGrey',
          width: '110px',
        },
        {
          text: 'Manager',
          value: 'isManager',
          align: 'center',
          class: 'lightGrey',
          width: '100px',
        },
        {
          text: 'Safety',
          value: 'isSafetyRep',
          align: 'center',
          class: 'lightGrey',
          width: '110px',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'lightGrey',
          width: '150px',
          sortable: false,
        },
      ],
      filters: {
        ...this.$constants.EMPLOYEE_LIST_FILTERS,
      },
      options: {
        sortBy: ['fullName'],
        sortDesc: [false],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
    };
  },
  computed: {
    isCompanyActive() {
      return this.$store.getters.selectedCompany.status;
    },
    filtersApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.EMPLOYEE_LIST_FILTERS)
      );
    },
    defaultFilterWithDynamicFields() {
      const filter = this.$constants.EMPLOYEE_LIST_FILTERS;
      for (const field of this.defaultCustomInputFields) {
        filter[`${field.name}.${field.id}`] = '';
      }
      return filter;
    },
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        this.updateSortDesc();
        const optionKeysToCheck = [
          'page',
          'itemsPerPage',
          'sortBy',
          'sortDesc',
        ];

        for (const key of optionKeysToCheck) {
          if (oldVal[key].toString() != newVal[key].toString()) {
            this.updateUrl();
            break;
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.onPageLoad();
  },
  methods: {
    async onPageLoad() {
      await this.getCompanyLicenses();
      await this.getCustomFieldsByOrganization().then(() => {
        this.injectFiltersWithDynamicFields();
        this.serializeRouteQuery();

        this.$refs.filters.setDefaultFilters(
          this.defaultFilterWithDynamicFields,
        );
        this.$refs.filters.setFilters(this.filters);
      });
      await this.getUsers();

      this.$refs.searchBar.setSearch(this.filters.search);
      this.reloadMassEdit = !this.reloadMassEdit;
      this.isFilterNeedingToReload = true;
    },
    onExportUsers(includeItems = false) {
      const params = {
        filters: this.filters,
        options: this.options,
        includeItems: includeItems,
        loaderText: 'Loading...',
      };

      if (
        this.$store.getters.user.type ===
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      ) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('export-users-by-company?format=json', params, options)
        .then((response) => {
          this.$helpers.downloadFile(response.data, 'User Export.xlsx');
        })
        .catch((error) => error);
    },
    onChangeUserStatus(user, newStatus) {
      const confirm = () => {
        const params = {
          userId: user.id,
          status: newStatus,
          loaderText: 'Updating status...',
        };
        this.$axios.post('update-user-status?format=json', params).then(() => {
          this.onPageLoad();
        });
      };
      if (newStatus == 0) {
        this.$root.showMessage(
          'Confirm',
          'Are you sure? Deactivating a user will unassign all items and remove the groups.',
          () => confirm(),
          () => false,
          'Confirm',
          'Cancel',
        );
      } else {
        confirm();
      }
    },
    onUpdateUserPermission(user, column) {
      const confirm = () => {
        const params = {
          id: user.id,
          columnName: column,
          loaderText: 'Updating permissions...',
          snackText: 'Permissions have been updated.',
        };
        this.$axios
          .post('update-user-permission?format=json', params)
          .then((response) => {
            this.getCompanyLicenses().then(() => {
              this.refreshUser(user, response.data);
            });
          })
          .catch(() => {
            this.getUsers();
          });
      };
      if (column == 'isSupervisor' && user.isSupervising) {
        this.$root.showMessage(
          'Warning',
          'If the user is added as a supervisor to any employee(s), they will be automatically removed. Are you sure you want to proceed?',
          () => confirm(),
          () => (user[column] = user[column] ? 0 : 1),
          'Confirm',
          'Cancel',
        );
      } else {
        confirm();
      }
    },
    onApplyFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.options.page = 1;

      this.updateUrl();
    },
    onSearch(event) {
      this.filters.search = event;
      this.options.page = 1;
      this.updateUrl();
    },
    getCompanyLicenses() {
      const url = 'get-company-licenses?format=json';
      const params = {
        id: this.$store.getters.isInternalAdmin
          ? this.$route.params.companyId
          : this.$store.getters.user.companyId,
        loaderText: 'Loading...',
      };

      return this.$axios
        .post(url, params)
        .then((response) => {
          if (this.$store.getters.isInternalAdmin) {
            this.$store.commit('updateSelectedCompany', {
              id: response.data.companyId,
              name: response.data.companyName,
              status: response.data.status,
              companyGroupId: response.data.companyGroupId,
            });
          }
          this.pageInstruction.title = response.data.companyName;
          this.licenses.activeUsers = response.data.activeUsers;

          /*
           * Companies NOT apart of a group will show their paid licenses next to the active user count
           * because the active user count, if greater than their paid licenses count, will be the number of
           * licenses they need to pay for. This cannot be accomplished with groups due to the need of certain users
           * needing multiple web admin accounts and only being charged for 1 license. In essence, groups are pretty
           * complex and it's not as simple as using the paid license number.
           */
          let content = `Active users: ${this.licenses.activeUsers}/`;
          if (response.data.companyGroupId) {
            this.licenses.licenses = response.data.licenses;
            content += `${response.data.licenses} (Group licensing)`;
          } else {
            this.licenses.licenses = response.data.paidLicenses;
            content += `${response.data.paidLicenses}`;
          }

          this.pageInstruction.contents = [content];
        })
        .catch((error) => error);
    },
    getUsers() {
      const params = {
        options: this.options,
        filters: this.filters,
        loaderText: 'Loading...',
      };
      if (this.$store.getters.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }
      this.users = [];
      return this.$axios
        .post('get-users-by-company?format=json', params)
        .then((response) => {
          this.users = response.data.items;
          this.resultCount = response.data.resultCount;
          this.totalRecordCount = response.data.totalRecordCount;
        })
        .catch((error) => error);
    },
    getCustomFieldsByOrganization() {
      const url = 'get-input-fields-for-organization?format=json';
      const params = {
        loaderText: 'Loading...',
        orgId: this.$store.getters.isInternalAdmin
          ? this.$store.getters.selectedCompany.id
          : this.$store.getters.user.companyId,
      };

      return this.$axios.post(url, params).then((response) => {
        this.defaultCustomInputFields = response.data;
      });
    },
    goToMassEdit() {
      if (this.$store.getters.isInternalAdmin) {
        this.$router.push({
          name: 'IAMassEditUsers',
        });
      } else {
        this.$router.push({
          name: 'MassEditUsers',
        });
      }
    },
    goToDetail(employee) {
      if (this.$store.getters.isInternalAdmin) {
        this.$router.push({
          name: 'IAUserDetails',
          params: { id: employee.id, companyId: employee.companyId },
        });
      } else {
        this.$router.push({
          name: 'UserDetails',
          params: { id: employee.id },
        });
      }
    },
    goToCreateUser() {
      this.$store.commit('updateSelectedUser', {
        id: 0,
        username: '',
        firstName: 'New User',
        lastName: '',
      });

      if (this.$store.getters.isInternalAdmin) {
        this.$router.push({
          name: 'IAUserDetails',
          params: {
            id: 0,
            companyId: this.$store.getters.selectedCompany.id,
          },
        });
      } else {
        this.$router.push({
          name: 'UserDetails',
          params: { id: 0 },
        });
      }
    },
    updateSortDesc() {
      // if new [sort by] is not 'email' or 'name' and current [sort by] not equeal to new [sort by], change [sort desc] to true
      if (
        this.options.sortBy.length &&
        this.options.sortBy[0] !== 'fullName' &&
        this.options.sortBy[0] !== 'email' &&
        this.options.sortBy[0] !== this.$route.query.sortBy
      ) {
        this.options.sortDesc[0] = true;
        return;
      }

      if (
        this.options.sortBy[0] !== 'fullName' &&
        this.options.sortBy[0] !== 'email' &&
        this.options.sortBy.length == 0 &&
        this.options.sortDesc.length == 0
      ) {
        this.options.sortDesc[0] = false;
        this.options.sortBy[0] = this.$route.query.sortBy;
      }
    },
    updateSession(permissions) {
      const session = JSON.parse(JSON.stringify(this.$store.getters.user));
      session.isSupervisor = permissions.isSupervisor;
      session.isManager = permissions.isManager;
      session.isSafetyRep = permissions.isSafetyRep;

      this.$store.commit('updateUser', session);
    },
    updateUrl() {
      const filters = this.$helpers.serializeFilter(
        this.filters,
        this.defaultFilterWithDynamicFields,
      );

      const options = this.$helpers.serializeOptions(
        this.options,
        'fullName',
        false,
      );
      const params = { ...options, ...filters };
      this.$router
        .push({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    is1LifeAccount(item) {
      // Internal Admin & 1Life accounts are not restricted
      if (
        this.$store.getters.isInternalAdmin ||
        this.$store.getters.user.firstName.toLowerCase() ==
          this.$constants.ONE_LIFE_ACCOUNT.FIRST_NAME
      ) {
        return false;
      }

      // Prevent deactivating 1Life account
      if (
        item.firstName.toLowerCase() ==
        this.$constants.ONE_LIFE_ACCOUNT.FIRST_NAME
      ) {
        return true;
      }

      return false;
    },
    isOwnAccount(item) {
      // Prevent deactivating own account
      if (
        this.$store.getters.user.id == item.id &&
        this.$store.getters.isWebAdmin
      ) {
        return true;
      }
    },
    refreshUser(user, newPermissions) {
      for (const key in newPermissions) {
        user[key] = newPermissions[key];
      }
      if (!user.isSupervisor) {
        user.isSupervising = 0;
      }
      if (user.id == this.$store.getters.user.id) {
        this.updateSession(newPermissions);
      }
      this.$forceUpdate();
    },
    serializeRouteQuery() {
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(query, this.filters);
      this.options = this.$helpers.deserializeOptions(query, 'fullName');
    },
    injectFiltersWithDynamicFields() {
      for (const field of this.defaultCustomInputFields) {
        this.filters[`${field.name}.${field.id}`] =
          this.filters[`${field.name}.${field.id}`] ?? '';
      }
    },
  },
};
</script>
