<template>
  <v-alert text color="info" dense>
    <v-row align="center" no-gutters>
      <v-col class="grow">
        <div class="text-h6">{{ title }}</div>
      </v-col>
      <v-col class="shrink">
        <v-btn icon small @click="expand = !expand">
          <v-icon color="info">{{
            expand ? 'mdi-chevron-up' : 'mdi-chevron-down'
          }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-show="expand">
        <div v-if="subtitle != undefined" class="text-subtitle-2">
          {{ subtitle }}
        </div>
        <ul>
          <li
            v-for="(item, i) in contents"
            :key="i"
            v-html="item"
            class="text-body-2 black--text"
            @click="clicked"
          ></li>
        </ul>
      </div>
    </v-expand-transition>
  </v-alert>
</template>
<script>
export default {
  name: 'PageInstruction',
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    contents: {
      type: Array,
    },
    clickEventId: {
      type: String,
    },
  },
  data() {
    return {
      expand: this.$vuetify.breakpoint.width > 750,
    };
  },
  methods: {
    clicked(e) {
      if (this.clickEventId != undefined && e.target.id == this.clickEventId) {
        this.$emit('clicked');
      }
    },
  },
};
</script>
